import Auth from "@aws-amplify/auth"
import { GenericSegment } from "@components/GenericBannerContainer/GenericBannerContainer.styles"
import { LoadingIcon } from "@components/OrderConfirmation/OrderConfirmation.styles"
import { UserContentEmpty } from "@privateComponents/private.styles"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import { Disclaimer, H6 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { ListingDetailsType } from "@utils/ListingTypes"
import { mockFeaturedListings, mockStandardListings } from "@utils/mockData"
import React, { useState, useEffect } from "react"
import { Container, Divider, Grid } from "semantic-ui-react"
import AllListings from "./AllListings"
import FeaturedListingSliderContainer from "./FeaturedListingsContainer"
import ListingFilters from "./ListingFilters"

export type ListingFiltersType = {
  keyword?: string
  sort: "Newest" | "Oldest" | "LTH" | "HTL" | "AZ" | "ZA"
  priceRange: "All" | "500" | "1000" | "2000" | "5000" | "10000" | "50000"
}

const ListingView = (props: any) => {
  const { header, description } = props
  const [listings, setListings] = useState<ListingDetailsType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<ListingFiltersType>({
    sort: "Newest",
    priceRange: "All",
  })
  useEffect(() => {
    setIsLoading(true)
    MarketplaceListingService.getAllListings(props.apiUrl).then((data: any) => {
      data = data.data.Data.map((data: any) => {
        return {
          ...data,
          imageUrls: data.imageUrls ? JSON.parse(data.imageUrls) : [],
        }
      })
      setListings(data)
      setIsLoading(false)
    })
  }, [])

  const featuredListings = listings.filter((listing: any) => {
    return listing.isFeatured
  })
  return (
    <GenericSegment
      basic
      bgColor="#F8F8F8"
      padding={{
        mobile: {
          top: 0,
          bottom: 0,
        },
        desktop: {
          top: 0,
          bottom: 0,
        },
      }}
    >
      <ListingFilters filters={filters} setFilters={setFilters} />
      <Container style={{ paddingTop: 40, paddingBottom: 30 }}>
        <Disclaimer
          color={theme.brand.colors.black}
          dangerouslySetInnerHTML={{ __html: header }}
        ></Disclaimer>
        <Disclaimer
          color={theme.brand.colors.black}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Disclaimer>
      </Container>

      {!isLoading && (
        <Container>
          {featuredListings.length > 0 && (
            <FeaturedListingSliderContainer listings={featuredListings} />
          )}
          <Divider />
          <AllListings
            listings={listings}
            pageSize={9}
            listingFilters={filters}
          />
        </Container>
      )}
      {isLoading && (
        <UserContentEmpty>
          <Container>
            <Grid>
              <Grid.Column textAlign="center" width={16}>
                <H6>
                  Loading Listings
                  <LoadingIcon name="circle notch" size="large" loading />
                </H6>
              </Grid.Column>
            </Grid>
          </Container>
        </UserContentEmpty>
      )}
    </GenericSegment>
  )
}

export default ListingView
