import {
  FeaturedIcon,
  FeaturedListingItemCardContent,
  FeaturedListingItemCardImage,
  FeaturedListingItemCardImageWrapper,
  FeaturedListingItemCardMain,
  FeaturedListingItemCombinationMobile,
  FeatureListingItemCombination,
} from "@components/Listings/Listings.styles"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { ListingDetailsType } from "@utils/ListingTypes"
import React, { Fragment } from "react"
import { Divider, Responsive } from "semantic-ui-react"
import {
  FeaturedListingItemCardContainer,
  FeaturedListingItemPriceTag,
  ListingItemFooterSection,
  ListingItemInfo,
  ListingItemInfoSection,
  ListingItemPricing,
  ListingPlateImage,
  ListingPlateImageContainer,
  StandardListingItemCardContainer,
} from "./ListingItemCard.styles"

export type FeaturedListingItemType = {
  combination: string
  askingPrice: number
  listingId: number
  // plateDesign: string,
  startDate: Date
}

const FeaturedListingItemDesktopCard = (props: ListingDetailsType) => {
  const imageUrl = `https://api.kiwiplates.nz/api//image/68/${props.combination}/4/4/max/450`
  const link = `/listing-detail?listingId=${props.listingId}`
  return (
    <FeaturedListingItemCardContainer>
      <FeaturedListingItemCardMain>
        <FeaturedListingItemCardImageWrapper>
          <FeaturedListingItemCardImage src={imageUrl} alt={props.combination}/>
        </FeaturedListingItemCardImageWrapper>
        <FeaturedListingItemCardContent>
          <FeaturedIcon>Featured</FeaturedIcon>
          <FeatureListingItemCombination>
            {props.combination}
          </FeatureListingItemCombination>
          <FeaturedListingItemPriceTag>
            ${props.price}
          </FeaturedListingItemPriceTag>
          <Divider />
          <KpButton
            id="btn-featured-vd"
            color={theme.brand.colors.blue}
            link={link}
            buttonType={ButtonTypes.Primary}
          >
            View Details
          </KpButton>
        </FeaturedListingItemCardContent>
      </FeaturedListingItemCardMain>
    </FeaturedListingItemCardContainer>
  )
}

const FeaturedListingItemMobileCard = (props: ListingDetailsType) => {
  const imageUrl = `https://api.kiwiplates.nz/api//image/68/${props.combination}/4/4/max/400`
  const link = `/listing-detail?listingId=${props.listingId}`
  return (
    <StandardListingItemCardContainer>
      <ListingPlateImageContainer>
        <ListingPlateImage width="100%" src={imageUrl} alt={props.combination}/>
      </ListingPlateImageContainer>
      <Divider />
      <ListingItemInfoSection>
        <ListingItemInfo>
          <FeaturedListingItemCombinationMobile>
            {props.combination}
          </FeaturedListingItemCombinationMobile>
          {/* {
                        props.plateDesign && 
                        <ListingItemPlateDesign color={theme.brand.colors.darkGrey}>
                            {props.plateDesign}
                        </ListingItemPlateDesign>
                    } */}
          <FeaturedListingItemPriceTag>
            ${props.price}
          </FeaturedListingItemPriceTag>
        </ListingItemInfo>
        <ListingItemPricing>
          <FeaturedIcon>Featured</FeaturedIcon>
        </ListingItemPricing>
      </ListingItemInfoSection>
      <ListingItemFooterSection>
        <KpButton
          id="vd"
          fullWidth="mobile tablet computer"
          buttonType={ButtonTypes.Primary}
          color={theme.brand.colors.blue}
          link={link}
        >
          View Details
        </KpButton>
      </ListingItemFooterSection>
    </StandardListingItemCardContainer>
  )
}

const FeaturedListingItemCard = (props: ListingDetailsType) => {
  return (
    <Fragment>
      <Responsive {...Responsive.onlyComputer}>
        <FeaturedListingItemDesktopCard {...props} />
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
        <FeaturedListingItemMobileCard {...props} />
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <FeaturedListingItemMobileCard {...props} />
      </Responsive>
    </Fragment>
  )
}
export default FeaturedListingItemCard
