import styled from "@emotion/styled"
import { H6 } from "@styles/Global.styles"
import mq from "@styles/mq"
import theme from "@styles/theme"
import React from "react"
import { Button, Container, Dropdown, Grid, Input } from "semantic-ui-react"

export const ListingFilterWrapper = styled.div`
  display: flex;
`
export const ListingFilterAction = styled.div`
  margin-left: auto;
  display: flex;
`
export const NavFilterComputer = styled.div`
  display: flex;
`
export const FilterButton = styled(Button)`
  border: 2px solid white !important;
  width: 50px;
  height: 50px;
  margin: 16px 4px !important;
`
export const SerachInput = styled(Input)`
  padding: 16px 30px 16px 30px;
  font-size: 16px !important;
  width: 100% !important;
  line-height: 22px;
  font-weight: bold !important;
  & > button {
    background-color: #707070 !important;
  }
`

export const FilterDropdown = styled(Dropdown)`
  line-height: 42px;
  padding: 16px 30px 16px 0;
  font-size: 16px;
  font-weight: bold;
`
export const ListingCount = styled.div`
  padding: 16px 30px 16px 0;
  line-height: 46px;
`
export const ListMyPlateButton = styled.div`
  padding: 16px 0px 16px 0px;
`
export const FeaturedListingItemCardImage = styled.img`
  object-fit: contain;
  width: 100%;
`
export const FeaturedListingItemCardImageWrapper = styled.div`
  object-fit: contain;
  display: flex;
  padding: 30px;
  width: 62%;
`
export const FeaturedListingItemCardContent = styled.div`
  width: 38%;
  padding: 50px 20px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #f6f6f6;
`
export const FeaturedIcon = styled.span`
  background-color: ${theme.brand.colors.blue};
  font-size: 12px;
  line-height: 16px;
  border-radius: 5px;
  padding: 4px 8px;
  color: white;
`
export const FeatureListingItemCombination = styled.div`
  ${mq({
    fontSize: ["20px", "22px", "24px"],
    lineHeight: ["28px", "28px", "30px"],
    letterSpacing: ["-0.4px", "-0.4px", "-0.48px"],
  })}
  font-weight: bold;
  letter-spacing: 0;
  padding-top: 20px;
  color: ${props => props.color};
`
export const FeaturedListingItemCombinationMobile = styled.div`
  ${mq({
    fontSize: ["20px", "22px", "24px"],
    lineHeight: ["28px", "28px", "30px"],
    letterSpacing: ["-0.4px", "-0.4px", "-0.48px"],
  })}
  font-weight: bold;
  letter-spacing: 0;
  color: ${props => props.color};
`
export const FeaturedListingItemCardMain = styled.div`
  display: flex;
`
export const FeaturedListingsContainer = styled(Container)`
  ${mq({
    padding: ["30px 0", "30px 0", "40px 0"],
  })}
`
export const PaginationControl = styled.div`
  padding: 30px;
  text-align: center;
`
export const MobileSearchControl = styled.div`
  width: 100%;
`
export const MobileFilterControl = styled(Container)``
export const MobileControlWrapper = styled(({ show, ...others }) => (
  <div {...others} />
))`
  background-color: #eeeeee;
  visibility: ${props => (props.show ? "visible" : "hidden")};
  display: ${props => (props.show ? "flex" : "none")};
`
