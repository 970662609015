import StandardListingItemCard from "@components/ListingItemCard/StandardListingItemCard"
import { date } from "@storybook/addon-knobs"
import { ListingDetailsType } from "@utils/ListingTypes"
import { StandardListingType } from "@utils/mockData"
import React, { SyntheticEvent, useState } from "react"
import { Container, Grid, Pagination, Responsive } from "semantic-ui-react"
import { PaginationControl } from "./Listings.styles"
import { ListingFiltersType } from "./ListingView"


type AllListingProps = {
    listings: ListingDetailsType[],
    pageSize: number,
    listingFilters: ListingFiltersType
}

const AllListings = (props:AllListingProps) =>{
    const [pageNumber, setPageNumber] = useState(1);
    let filteredListings = props.listings;
    switch(props.listingFilters.sort){
        case "Oldest":
            filteredListings = props.listings.sort(function(a,b){
                return (a.expiresOn ? new Date(a.expiresOn).getTime() : 0) - (b.expiresOn ? new Date(b.expiresOn).getTime() : 0);
            })
            break;
        case "LTH":
            filteredListings = props.listings.sort(function(a,b){
                return a.price - b.price;
            })
            break;
        case "HTL":
            filteredListings = props.listings.sort(function(a,b){
                return b.price - a.price;
            })
            break;
        case "AZ":
            filteredListings = props.listings.sort(function(a,b){
                return a.combination > b.combination ? 1 : -1;
            })
            break;
        case "ZA":
            filteredListings = props.listings.sort(function(a,b){
                return a.combination < b.combination ? 1 : -1;
            })
            break;
        default:
            filteredListings = props.listings.sort(function(a,b){
                return (b.expiresOn ? new Date(b.expiresOn).getTime() : 0) - (a.expiresOn ? new Date(a.expiresOn).getTime() : 0);
            })
    }

    switch(props.listingFilters.priceRange){
        case "500":
            filteredListings = filteredListings.filter(l=>l.price <=500);
            break;
        case "1000":
            filteredListings = filteredListings.filter(l=>l.price <=1000 && l.price >500);
            break;
        case "2000":
            filteredListings = filteredListings.filter(l=>l.price <=2000 && l.price > 1000);
            break;
        case "5000":
            filteredListings = filteredListings.filter(l=>l.price <=5000 && l.price > 2000);
            break;
        case "10000":
            filteredListings = filteredListings.filter(l=>l.price <=10000 && l.price > 5000);
            break;
        case "50000":
            filteredListings = filteredListings.filter(l=>l.price <=50000 && l.price > 10000);
            break;
        default:
            filteredListings = filteredListings;
    }
    if(props.listingFilters.keyword && props.listingFilters.keyword !== undefined){
        const keyword = props.listingFilters.keyword;
        filteredListings = filteredListings.filter(l=>{
            if(l.combination.toUpperCase().includes(keyword.toUpperCase()))
                return l;
        })
    }

    const currentListings = filteredListings.slice(props.pageSize * (pageNumber -1),props.pageSize * pageNumber);
    const totalPages = Math.ceil(filteredListings.length / props.pageSize);
    const onPageChange = (event: SyntheticEvent, data: any) =>{
        setPageNumber(data.activePage);
        const anchor = document.getElementById('allListingsSection');
        anchor?.scrollIntoView();
    }
    return (
        <Container id="allListingsSection">
            <Responsive {...Responsive.onlyComputer}>
                    <Grid columns={3}>
                    {
                        currentListings.map(item=>{
                        return (
                            <Grid.Column>
                            <StandardListingItemCard {...item} />
                            </Grid.Column>
                            );
                        })
                    }
                    </Grid>
                    <PaginationControl>
                        <Pagination 
                            boundaryRange={0}
                            defaultActivePage={1}
                            totalPages= {totalPages}
                            onPageChange={onPageChange}
                            />
                    </PaginationControl>
            </Responsive>

            <Responsive {...Responsive.onlyTablet}>
                <Grid columns={2}>
                {
                    currentListings.map(item=>{
                    return (
                        <Grid.Column>
                        <StandardListingItemCard {...item} />
                        </Grid.Column>
                        );
                    })
                }
                </Grid>

                <PaginationControl>
                        <Pagination 
                            boundaryRange={0}
                            defaultActivePage={1}
                            totalPages= {totalPages}
                            onPageChange={onPageChange}
                            />
                </PaginationControl>
            </Responsive>
            

            <Responsive {...Responsive.onlyMobile}>
                <Grid columns={1}>
                {
                    currentListings.map(item=>{
                    return (
                        <Grid.Column>
                        <StandardListingItemCard {...item} />
                        </Grid.Column>
                        );
                    })
                }
                </Grid>

                <PaginationControl>
                        <Pagination 
                            boundaryRange={0}
                            defaultActivePage={1}
                            totalPages= {totalPages}
                            onPageChange={onPageChange}
                            />
                </PaginationControl>
            </Responsive>
        </Container>
    )
}
export default AllListings