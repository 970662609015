import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"
import { ListingView } from "@components/Listings"
import { FaqList, mapToFaqContainer } from "@components/FaqList"
import theme from "@styles/theme"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import AuthProvider from "@auth/AuthContext"

const Marketplace = (props: any) => {
  const { elements } = props.data.kontentItemMarketplaceListings
  const seo = mapToSEO(elements)
  const kiwiVip = mapToKiwiVIPVisible(elements)
  const metadata = mapToPageHeader(elements)
  const apiUrl = props.data.site.siteMetadata.apiUrl

  const faqs = mapToFaqContainer(
    elements.marketplace_faqs.linked_items[0].elements
  )
  const header = elements.header.value
  const description = elements.description.value
  return (
    <Layout location={props.location} authEnabled={true}>
      <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />

      <ListingView apiUrl={apiUrl} header={header} description={description} />
      <GenericBannerContainer
        padding={{
          mobile: { top: 50, bottom: 60 },
          desktop: { top: 90, bottom: 100 },
        }}
        backgroundColor={faqs.backgroundColor}
      >
        <FaqList
          {...faqs}
          answerColor={theme.brand.colors.black}
          questionColor={theme.brand.colors.black}
          borderColor={theme.brand.colors.black}
        />
      </GenericBannerContainer>
      {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemMarketplaceListings {
      elements {
        general_page_content__title_position {
          value {
            codename
          }
        }
        seo__page_description {
          value
        }
        url {
          value
        }
        general_page_content__title {
          value
        }
        seo__nofollow {
          value {
            codename
          }
        }
        seo__page_title {
          value
        }
        header {
          value
        }
        description {
          value
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
          }
        }
        seo__noindex {
          value {
            codename
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
          }
        }
        marketplace_faqs {
          linked_items {
            ... on KontentItemFaqBlock {
              elements {
                background_colors {
                  value {
                    codename
                    name
                  }
                }
                description{
                  value
                }
                faqs {
                  linked_items {
                    ... on KontentItemFaqItem {
                      elements {
                        answer {
                          value
                        }
                        faq_category {
                          value {
                            codename
                            name
                          }
                        }
                        question {
                          type
                          value
                        }
                      }
                    }
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Marketplace
