import { NextArrow, PrevArrow } from "@components/CustomerReviewCardSlider/CustomerReviewCardSlider";
import { InstagramSliderArrow } from "@components/Instagram/InstagramSlider.styles";
import FeaturedListingItemCard, { FeaturedListingItemType } from "@components/ListingItemCard/FeaturedListingItemCard";
import { ListingDetailsType } from "@utils/ListingTypes";
import React from "react";
import Slider from "react-slick";
import { Container, Responsive } from "semantic-ui-react";
import { FeaturedListingsContainer } from "./Listings.styles";

type FeaturedListingsProps ={
    listings: ListingDetailsType[]
}

const FeaturedListingSliderContainer = (props: FeaturedListingsProps) =>{
    const desktopSliderSettings = {
        dots: true,
        autoplay: true,
        autoPlaySpeed: 3000,
        slidesToShow: props.listings.length === 1 ? 1 : 2,
        slidesToScroll: 1,
        draggable: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }    
    const tabletSliderSettings = {
        dots: true,
        autoplay: true,
        autoPlaySpeed: 3000,
        slidesToShow: props.listings.length === 1 ? 1 : 2,
        slidesToScroll: 1,
        draggable: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }
    const mobileSliderSettings = {
        dots: true,
        autoplay: true,
        autoPlaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }
    return (
        <FeaturedListingsContainer>
            <Responsive {...Responsive.onlyComputer}>
                <Slider {...desktopSliderSettings}>
                    {
                        props.listings.map(item=>{
                            return <FeaturedListingItemCard {...item} />
                        })
                    }
                </Slider>
            </Responsive>
            <Responsive {...Responsive.onlyTablet}>
                <Slider {...tabletSliderSettings}>
                    {
                        props.listings.map(item=>{
                            return <FeaturedListingItemCard {...item} />
                        })
                    }
                </Slider>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <Slider {...mobileSliderSettings}>
                    {
                        props.listings.map(item=>{
                            return <FeaturedListingItemCard {...item} />
                        })
                    }
                </Slider>
            </Responsive>
        </FeaturedListingsContainer>
    )
}
export default FeaturedListingSliderContainer;