import { GenericSegment } from "@components/GenericBannerContainer/GenericBannerContainer.styles"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import React from "react"
import { Container, Responsive } from "semantic-ui-react"
import {
  FilterButton,
  FilterDropdown,
  ListingFilterAction,
  ListingFilterWrapper,
  ListMyPlateButton,
  MobileControlWrapper,
  MobileFilterControl,
  MobileSearchControl,
  NavFilterComputer,
  SerachInput,
} from "./Listings.styles"
import { ListingFiltersType } from "./ListingView"

const orderFilterOptions = [
  {
    key: "Newest First",
    text: "Newest First",
    value: "Newest",
  },
  {
    key: "Oldest First",
    text: "Oldest First",
    value: "Oldest",
  },
  {
    key: "Price Low to High",
    text: "Price Low to High",
    value: "LTH",
  },
  {
    key: "Price High to Low",
    text: "Price High to Low",
    value: "HTL",
  },
  {
    key: "A-Z",
    text: "A-Z",
    value: "AZ",
  },
  {
    key: "Z-A",
    text: "Z-A",
    value: "ZA",
  },
]

const priceFilterOptions = [
  {
    key: "All Prices",
    text: "All Prices",
    value: "All",
  },
  {
    key: "$0 to $500",
    text: "$0 to $500",
    value: "500",
  },
  {
    key: "$500 to $1000",
    text: "$500 to $1000",
    value: "1000",
  },
  {
    key: "$1000 to $2000",
    text: "$1000 to $2000",
    value: "2000",
  },
  {
    key: "$2000 to $5000",
    text: "$2000 to $5000",
    value: "5000",
  },
  {
    key: "$5000 to $10000",
    text: "$5000 to $10000",
    value: "10000",
  },
  {
    key: "$10000 to $50000",
    text: "$10000 to $50000",
    value: "50000",
  },
]

type ListingFilterPropsType = {
  filters: ListingFiltersType
  setFilters: Function
}

const ListingFilters = (props: ListingFilterPropsType) => {
  const [showSearchControl, setShowSearchControl] = React.useState(false)
  const [showFilterControl, setShowFilterControl] = React.useState(false)
  const updatePriceFilter = (e: any, data: any) => {
    props.setFilters({ ...props.filters, priceRange: data.value })
    // console.log(data.value)
  }
  const updateOrderFilter = (e: any, data: any) => {
    props.setFilters({ ...props.filters, sort: data.value })
    // console.log(data.value)
  }
  const setSearchControlVisible = () => {
    if (!showSearchControl) {
      //if this control WAS invisible, make sure other control is invisible
      setShowFilterControl(false)
    }
    setShowSearchControl(!showSearchControl)
  }
  const setFilterControlVisible = () => {
    if (!showFilterControl) {
      setShowSearchControl(false)
    }
    setShowFilterControl(!showFilterControl)
  }
  return (
    <GenericSegment
      basic
      bgColor="#E5E5E5"
      padding={{
        mobile: {
          top: 0,
          bottom: 0,
        },
        desktop: {
          top: 0,
          bottom: 0,
        },
      }}
    >
      <Container>
        <ListingFilterWrapper>
          <Responsive {...Responsive.onlyMobile}>
            <FilterButton icon="search" onClick={setSearchControlVisible} />
            <FilterButton icon="filter" onClick={setFilterControlVisible} />
          </Responsive>
          <Responsive {...Responsive.onlyTablet}>
            <FilterButton icon="search" onClick={setSearchControlVisible} />
            <FilterButton icon="filter" onClick={setFilterControlVisible} />
          </Responsive>
          <Responsive {...Responsive.onlyComputer} as={NavFilterComputer}>
            <div>
              <SerachInput
                type="text"
                action={{ icon: "search" }}
                placeholder="Search..."
                maxLength="6"
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => props.setFilters({ keyword: e.target.value })}
              />
            </div>
            <div>
              <FilterDropdown
                onChange={updateOrderFilter}
                options={orderFilterOptions}
                defaultValue="Newest"
              ></FilterDropdown>
            </div>
            <div>
              <FilterDropdown
                onChange={updatePriceFilter}
                options={priceFilterOptions}
                defaultValue="All"
              ></FilterDropdown>
            </div>
          </Responsive>

          <ListingFilterAction>
            {/* <ListingCount>
                            512 Plates
                        </ListingCount> */}
            <ListMyPlateButton>
              <KpButton
                id="listing-new-btn"
                color={theme.brand.colors.green}
                link="/app/new-listing"
                buttonType={ButtonTypes.Primary}
              >
                List My Plate
              </KpButton>
            </ListMyPlateButton>
          </ListingFilterAction>
        </ListingFilterWrapper>
      </Container>

      <MobileControlWrapper show={showSearchControl}>
        <MobileSearchControl>
          <SerachInput
            fluid
            type="text"
            action={{ icon: "search" }}
            placeholder="Search..."
            maxLength="6"
            onChange={(e: {
              target: { value: React.SetStateAction<string> }
            }) => props.setFilters({ keyword: e.target.value })}
          />
        </MobileSearchControl>
      </MobileControlWrapper>

      <MobileControlWrapper show={showFilterControl}>
        <MobileFilterControl>
          <div>
            <FilterDropdown
              onChange={updateOrderFilter}
              options={orderFilterOptions}
              defaultValue="Newest"
            ></FilterDropdown>
          </div>
          <div>
            <FilterDropdown
              onChange={updatePriceFilter}
              options={priceFilterOptions}
              defaultValue="All"
            ></FilterDropdown>
          </div>
        </MobileFilterControl>
      </MobileControlWrapper>
    </GenericSegment>
  )
}
export default ListingFilters
